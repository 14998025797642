<template>
  <section class="loose-threads">
    <div class="loose-threads__header">
      <h2 class="loose-threads__title text-title">Loose Threads</h2>
      <div class="loose-threads__header-nav">
        <BaseButton
          v-for="(item, index) in types"
          :key="`type-${index}`"
          class="loose-threads__header-nav-button button--xs button--uppercase"
          :class="{ 'button--outline-white': !item.active, 'button--white': item.active }"
          :text="item.title"
          @click="openType(item.key)"
        ></BaseButton>
      </div>
    </div>
    <div class="loose-threads__content">
      <IconButton
        class="loose-threads__sidebar-button button--md button--icon-white"
        @click="sidebarShow = !sidebarShow"
      >
        <IconMenu></IconMenu>
      </IconButton>
      <div class="loose-threads__sidebar" :class="{ 'loose-threads__sidebar--show': sidebarShow }">
        <div
          class="loose-threads__category text-sub-title"
          :class="{ 'loose-threads__category--active': item.active }"
          v-for="(item, index) in typeList"
          :key="`looseThread-${index}`"
          @click="openLooseThread(item.key)"
        >
          <p class="loose-threads__category-text text-sub-title">{{ item.title }}</p>
        </div>
        <IconButton
          class="loose-threads__sidebar-close button--md button--outline-white"
          @click="sidebarShow = false"
        >
          <IconClose width="18" height="18"></IconClose>
        </IconButton>
      </div>
      <div class="loose-threads__container">
        <div class="loose-threads__item-content">
          <h2 class="loose-threads__item-title text-title" v-if="!loading && typeList.length === 0">
            {{ types[typeActive - 1].title }} don't have a loose threads
          </h2>
          <div class="loose-threads__item" v-if="!loading && typeList.length > 0">
            <h2 class="loose-threads__item-title text-title">
              {{ looseThread.loose_thread.title }}
            </h2>
            <div
              class="loose-threads__item-description collect-editor collect-editor--white"
              v-html="looseThread.loose_thread.description"
            ></div>
          </div>
          <IconLoader
            v-if="loading"
            class="loose-threads__loader loader--white loader--lg"
          ></IconLoader>
        </div>
        <div class="loose-threads__nav">
          <div
            class="loose-threads__nav-button"
            :class="{ 'loose-threads__nav-button--disabled': !looseThread.previous }"
            @click="openLooseThread(looseThread.previous)"
          >
            <IconButton
              class="carousel__button carousel__button--back button--circle button--white"
            >
              <IconArrowTop class="carousel__button-icon carousel__button-icon"></IconArrowTop>
            </IconButton>
            <span class="text-link">Previous</span>
          </div>
          <div
            class="loose-threads__nav-button"
            :class="{ 'loose-threads__nav-button--disabled': !looseThread.next }"
            @click="openLooseThread(looseThread.next)"
          >
            <span class="text-link">Next</span>
            <IconButton
              class="carousel__button carousel__button--next button--circle button--white"
            >
              <IconArrowTop class="carousel__button-icon carousel__button-icon"></IconArrowTop>
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconArrowTop from "@/components/icons/IconArrowTop.vue";
import IconLoader from "@/components/icons/IconLoader.vue";
import IconMenu from "@/components/icons/IconMenu.vue";
import IconClose from "@/components/icons/IconClose.vue";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  components: {
    BaseButton,
    IconButton,
    IconArrowTop,
    IconLoader,
    IconMenu,
    IconClose,
  },
  data() {
    return {
      loading: false,
      sidebarShow: false,
      typeActive: "",
    };
  },
  head: {
    title: function () {
      return {
        inner: "Loose Threads browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: "Loose Threads browngrotta arts",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Loose Threads browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Loose Threads browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Loose Threads browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: "Description Loose Threads browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Loose Threads browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: "Description Loose Threads browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Loose Threads browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  computed: {
    ...mapGetters("publicapi/looseThreads", {
      types: "getTypes",
      typeList: "getTypeList",
      looseThread: "getLooseThread",
    }),
  },
  async created() {
    this.loading = true;
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Media",
        to: "/media",
        clickable: true,
      },
      {
        title: "Loose Threads",
        to: "/media/loose-threads",
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
    await this.apiLooseThreadsTypes();
    await this.apiLooseThreadsTypeList(this.types[0].key);
    if (this.typeList.length > 0)
      await this.apiLooseThread({ type: this.types[0].key, slug: this.typeList[0].key });
    else this.setLooseThread({});
    this.typeActive = this.types[0].key;
    this.loading = false;
  },
  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    ...mapActions("publicapi/looseThreads", [
      "apiLooseThreadsTypes",
      "apiLooseThreadsTypeList",
      "apiLooseThread",
    ]),
    ...mapMutations("publicapi/looseThreads", [
      "setTypeListActive",
      "setTypeActive",
      "setLooseThread",
    ]),
    async openType(key) {
      this.loading = true;
      this.setTypeActive(key);
      this.typeActive = key;
      await this.apiLooseThreadsTypeList(key);
      if (this.typeList.length > 0) await this.openLooseThread(this.typeList[0].key);
      else this.setLooseThread({});
      this.loading = false;
    },
    async openLooseThread(slug) {
      this.loading = true;
      this.sidebarShow = false;
      this.setTypeListActive(slug);
      await this.apiLooseThread({ type: this.typeActive, slug });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.loose-threads {
  padding: 21rem 10.5rem 14.5rem;
  min-height: 100vh;
  position: relative;
  @media screen and (max-width: $md) {
    padding: 21rem 5rem 14.5rem;
  }
  @media screen and (max-width: $xs) {
    padding: 21rem 25px 14.5rem;
  }
  display: flex;
  flex-direction: column;
  &__title {
    text-align: center;
    font-size: 4rem;
    line-height: 1.25;
    letter-spacing: -1.33px;
    margin-bottom: 20px;
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 9rem;
    @media screen and (max-width: $xs) {
      margin-bottom: 6rem;
    }
    &-nav {
      display: flex;
      @media screen and (max-width: $xs) {
        flex-direction: column;
      }
      &-button {
        min-width: 18rem;
        margin-right: 15px;
        &:last-of-type {
          margin-right: 0;
        }
        @media screen and (max-width: $xs) {
          margin-right: 0;
          margin-bottom: 15px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &__sidebar {
    padding-right: 10px;
    min-width: 31.5rem;
    max-width: 25vw;
    flex-shrink: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 78.6rem;
    @media screen and (max-width: $md) {
      min-width: 28rem;
    }
    @media screen and (max-width: $sm) {
      position: absolute;
      right: 0;
      height: 100%;
      max-height: calc(100% - 67.1rem);
      max-width: 100%;
      background-color: $lightBlack;
      z-index: 1;
      padding: 10px 80px 10px 25px;
      transition: all 0.5s ease;
      transform: translateX(100%);
    }
    &--show {
      @media screen and (max-width: $sm) {
        transform: translateX(0);
      }
    }
    & > .loose-threads__category {
      cursor: pointer;
    }
    &-button {
      display: none;
      position: absolute;
      right: 25px;
      z-index: 1;
      border: none;
      border-radius: 50%;
      background-color: transparent;
      transform: translateY(-100%);
      &:hover {
        background-color: rgba($color: $white, $alpha: 0.2);
      }
      @media screen and (max-width: $sm) {
        display: block;
      }
    }
    &-close {
      display: none;
      position: absolute;
      top: 10px;
      right: 25px;
      @media screen and (max-width: $sm) {
        display: block;
      }
    }
  }
  &__category {
    width: max-content;
    max-width: 100%;
    padding: 5px 10px;
    border: 1px solid $lightGrey;
    border-radius: 19px;
    transition: all 0.3s ease;
    margin-bottom: 1.7rem;
    &-text {
      font-size: 1.6rem;
      line-height: 1.8;
      text-transform: uppercase;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      line-clamp: 1;
      text-overflow: clip;
      overflow: hidden;
    }
    &:hover {
      background-color: rgba($color: $white, $alpha: 0.3);
    }
    &--active {
      background-color: $white;
      .loose-threads__category-text {
        color: $black;
      }
      &:hover {
        background-color: $lightGrey;
      }
    }
  }
  &__container {
    padding-left: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: $xs) {
      padding-left: 0;
    }
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    &-button {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        margin: 0 15px;
      }
      &--disabled {
        pointer-events: none;
        opacity: 0.6;
      }
    }
  }
  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 1;
  }
  &__item {
    width: 100%;
    &-content {
      position: relative;
      width: 100%;
      height: 100%;
      padding-bottom: 4rem;
      overflow-y: scroll;
    }
    &-title {
      font-size: 4rem;
      line-height: 1.25;
      margin-bottom: 5px;
    }
    &-description {
      &.collect-editor ::v-deep * {
        font-family: $fontFrutigerNeue;
      }
      &.collect-editor ::v-deep p {
        font-weight: 300;
      }
    }
    &-footer {
      display: flex;
      justify-content: flex-end;
    }
  }
  &__link {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: $white;
    color: $black;
    font-family: $fontFrutiger;
    height: 5.2rem;
    padding: 0 24px;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.5;
    text-decoration: none;
    margin-top: 20px;
    @media (max-width: $xxs) {
      padding: 0 12px;
    }
    @media (max-width: $xxxs) {
      font-size: 1.6rem;
    }
    &:hover {
      background-color: $light2Grey;
    }
  }
  &__button {
    width: max-content;
    min-width: 16rem;
  }
  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.carousel {
  &-wrapper {
    position: relative;
    margin-bottom: 10px;
  }
  &__button {
    width: 4.6rem;
    height: 4.6rem;
    @media screen and (max-width: $xs) {
      width: 4.2rem;
      height: 4.2rem;
    }
    &--next {
      transform: rotate(90deg);
    }
    &--back {
      transform: rotate(-90deg);
    }
    &-icon {
      width: 18px;
      height: 12px;
      @media screen and (max-width: $xs) {
        width: 14px;
        height: 8px;
      }
    }
  }
}
</style>
